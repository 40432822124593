<template>
  <div>
    <div class="air__utils__heading">
      <h5>Reporte de Operaciones</h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'warehouse_report'}">
                <strong>
                  <b-iconstack font-scale="5">
                    <b-icon stacked  variant="primary"
                            icon="box" scale="0.15"
                            shift-v="0"/>
                    <b-icon stacked  variant="primary"
                            icon="box-seam" scale="0.15"
                            shift-v="-3" shift-h="2"/>
                    <b-icon stacked  variant="primary"
                            icon="box-seam" scale="0.15"
                            shift-v="-3" shift-h="-2"/>
                    <b-icon stacked icon="house" variant="primary"/>
                  </b-iconstack>
                </strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'warehouse_report'}">
              <h6 class="text-block text-secondary">Almacén</h6>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'operationMenu',
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Reportes',
          name: 'reports',
        },
        {
          breadcrumbName: 'Reportes de Operaciones',
          aIcon: '',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
